/* Values
============================================ */
$sidebarWidth: 260px;
$maxWidth: 1440px;

/* Fonts
============================================ */

/* Bootstrap
============================================ */

$size-sm: 575px; // 576px - 1px
$size-md: 767px; // 768px - 1px
$size-lg: 991px; // 992px - 1px
$size-xl: 1280px; // 1200px - 1px

$container-max-widths: (
  sm: $size-sm,
  md: $size-md,
  lg: $size-lg,
  xl: $size-xl,
);

$color-white: #ffffff;
$color-cultured: #fafafb;
$color-ghost-white: #f5f6fa;
$color-ghost-white-2: #e9e9f0;
$color-ghost-white-3: #f9f9fb;
$color-black: #000000;
$color-jet: #292a2b;
$color-onyx: #3c4043;
$color-rebecca-purple: #6e3da4;
$color-purple-plum: #8d5bc2;
$color-blue-bell: #a6a4be;
$color-fuzzy-wuzzy: #d25e62;
$color-mantis: #5cb85c;

$theme-colors: (
  "primary": $color-purple-plum,
  "secondary": $color-onyx,
  "success": $color-mantis,
  //  "info":     #9B9B9B,
  //  "warning":  #f0ad4e,
  "danger": $color-fuzzy-wuzzy,
  "light": $color-ghost-white-3,
  "dark": $color-jet,
);

$border-radius: 1rem !default;
$border-radius-lg: 1.25rem !default;
$border-radius-sm: 0.5rem !default;

$btn-border-radius: $border-radius-lg !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius !default;
$btn-disabled-opacity: 0.25 !default;

$input-border-radius: $border-radius-sm !default;
$input-border-radius-lg: $border-radius !default;

$custom-select-border-radius: $border-radius-sm !default;

$card-spacer-y: 1rem !default;
$card-header-spacer-x: 1.75rem !default;
$card-border-width: 2px !default;
$card-border-color: $color-ghost-white !default;

$enable-caret: false !default;

$modal-inner-padding: 1.5rem !default;
$modal-header-padding-x: 1.5rem !default;

$popover-border-radius: $border-radius-sm !default;
$popover-max-width: 450px !default;
$popover-arrow-width: 0.5rem !default;
$popover-arrow-height: 0.25rem !default;

$list-group-border-radius: 0 !default;
$list-group-border-width: 0 !default;
$list-group-bg: transparent !default;

@import "~bootstrap/scss/bootstrap";

/* Video React
============================================ */

@import "~video-react/styles/scss/video-react";

/* Customize
============================================ */

body {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-ghost-white-3;
  color: $color-jet;
}

p,
a {
  white-space: pre-line;
}

a {
  color: $color-jet;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.container {
  max-width: $maxWidth;
}

.btn.a {
  color: $color-jet;
  padding: 0;

  &:hover {
    color: $color-purple-plum;
    transition: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.font-weight-bold {
  font-weight: 600 !important;
}

.underline {
  text-decoration: underline;
}

.alert {
  padding: 1rem;
}

.alert-heading {
  font-size: 1rem;
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 0;
}

.bg-white {
  background-color: $color-white;
}

.toast-header {
  padding: 0.25rem 0.5rem;
  color: $color-mantis;
}

.toast {
  .toast-header {
    padding: 0.25rem 0.5rem;
  }
  &.success {
    .toast-header {
      color: $color-mantis;
    }
  }
  &.compressin,
  &.init {
    .toast-header {
      color: $color-onyx;
    }
  }
  &.error {
    .toast-header {
      color: $color-fuzzy-wuzzy;
    }
  }
  white-space: pre-line;
}

.fixed-toast {
  position: fixed;
  z-index: 100;
  right: 20px;
  top: 100px;
}

.icon {
  margin-bottom: 3px;
}

.br-1 {
  border-right: 1px solid $color-ghost-white;
}

.br-2 {
  border-right: 2px solid $color-ghost-white;
}

.bl-1 {
  border-left: 1px solid $color-ghost-white;
}

.bl-2 {
  border-left: 2px solid $color-ghost-white;
}

.card-header {
  background-color: $color-white;
  padding: $card-spacer-y $card-header-spacer-x;
}

.breadcrumb {
  background-color: $color-white;
  border: 1px solid rgba($color-black, 0.125);
  border-radius: 0.25rem;
}

.dropdown-btn-block {
  button {
    display: block;
    width: 100%;
  }

  .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 10%;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pre-wrap {
  white-space: pre-wrap;
}

.invalid-tooltip {
  font-size: 0.75rem;
  right: 0;
  left: auto;
  background-color: transparent;
  color: $color-fuzzy-wuzzy;
  line-height: 1;
  padding: 0.25rem;
}

.card {
  box-shadow: 1px 1px 5px 1px rgba($color-jet, 0.1);
  border-width: 0;
}

.list-group-item {
  padding: 0;
  border-bottom: 1px solid $color-ghost-white;
  border-top: 1px solid $color-ghost-white;
}

.tooltip-inner {
  max-width: 300px;
}

.text-prewrap {
  white-space: pre-line;
}

.btn-outline-dark {
  border-color: rgba($color-jet, 0.25);
}

@media only screen and (max-width: $size-xl) {
  .text-prewrap-xl {
    white-space: pre-line;
  }
}

@media only screen and (max-width: $size-sm) {
  .tooltip-inner {
    max-width: 200px;
  }
  .text-prewrap-sm {
    white-space: pre-line;
  }
}

/* Custom components
============================================ */

@mixin limit-text($rows) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $rows;
}

.limit-text-1 {
  @include limit-text(1);
}
.limit-text-2 {
  @include limit-text(2);
}
.limit-text-3 {
  @include limit-text(3);
}
.limit-text-4 {
  @include limit-text(4);
}
.limit-text-5 {
  @include limit-text(5);
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.slow-spin {
  animation: spin 1s linear infinite;
}

.btn-default-sm {
  min-width: 100px;
}

.btn-default {
  min-width: 120px;
}

.btn-default-lg {
  min-width: 200px;
}

@media only screen and (max-width: $size-sm) {
  .btn-block-xs-only {
    display: block;
    width: 100%;
  }
}

/* Auth
============================================ */

.auth {
  .bg-light-md {
    @media only screen and (min-width: $size-md) {
      background-color: $color-ghost-white-3;
    }
  }

  .title {
    font-size: 18px;
    color: $color-jet;
    opacity: 0.75;
  }

  .logo {
    width: 7rem;
    margin-bottom: 1.25rem;
  }

  .image-container {
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    height: auto;
    position: relative;

    .background-image {
      position: absolute;
      min-width: 100vh;
      width: 100%;
      height: 100%;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -ms-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;

      &.hidden {
        opacity: 0;
      }

      &.visible {
        opacity: 1;
      }

      img {
        object-fit: cover;
        min-width: 100vh;
        width: 100%;
        height: 100%;
      }

      .credits {
        position: absolute;
        bottom: 0;
        font-size: 9px;

        a {
          color: $color-white;
        }
      }
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
  }

  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding: 0.375rem;

    .invalid-tooltip {
      padding-left: 15px;
      padding-right: 15px;
    }

    &:focus {
      border-color: $color-purple-plum;
      box-shadow: none;
    }
  }

  .auth-form-container {
    position: absolute;
    right: 100px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $size-md) {
      position: relative;
      width: 100%;
      right: 0;
      left: 0;
    }
  }

  .auth-form-content {
    background: $color-white;
    width: 550px;
    min-height: 66%;
    padding: 50px 75px;
    border-radius: $border-radius-lg;
    box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    @media only screen and (max-width: $size-md) {
      width: auto;
      min-height: 50%;
      padding: 50px 25px;
      border: none;
      box-shadow: none;
    }
  }

  .footer-links {
    a {
      font-size: 80%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

/* Sidebar navigation
============================================ */

.sidebar-navigation {
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: $sidebarWidth;
  height: 100vh;
  transition: all 0.6s ease;

  &.show {
    transform: translate(0, 0);
  }

  &.hide {
    transform: translate(-$sidebarWidth, 0);
  }

  .dropdown-menu {
    margin-left: 0.25rem !important;
    border-width: 0;
    box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);
  }

  .nav-container {
    flex-wrap: nowrap;
    height: 100%;
    background-color: $color-white;
    box-shadow: 0px 1px 2px rgba($color-black, 0.1);
    -webkit-box-shadow: 0px 1px 2px rgba($color-black, 0.1);
    -moz-box-shadow: 0px 1px 2px rgba($color-black, 0.1);

    .logo {
      width: 7rem;
      margin: 3rem auto 1rem auto;
    }

    .nav-dest-selection {
      margin: 1.5rem 1rem;

      a {
        text-decoration: none;
      }

      .dest-container {
        padding: 1rem;
        background-color: $color-ghost-white;
        border-radius: 1rem;
        display: flex;
        flex-direction: row;
      }

      .title {
        margin: 0;
        font-weight: 600;
      }

      .subtitle {
        opacity: 0.5;
      }

      .left {
        flex: 1;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
      }
    }

    .nav-spacer {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }

    .nav-title {
      padding: 0 1.75rem;
      font-weight: 600;
      color: lighten($color-jet, 50%);
    }

    .nav-link {
      font-weight: 400;
      padding: 1rem 1.75rem;
      margin-top: 3px;
      margin-bottom: 3px;
      border-right: solid 3px $color-white;

      &:hover {
        background-color: $color-ghost-white;
        border-right-color: $color-ghost-white;
      }

      &.active {
        border-right-color: $color-rebecca-purple;
        font-weight: 600;
        svg {
          color: $color-purple-plum;
        }
      }

      svg {
        color: $color-blue-bell;
        font-size: 1.25rem;
        margin-bottom: 3px;
        margin-right: 10px;
      }
    }
  }
}

.sidebar-toggle {
  position: fixed;
  z-index: 1032;
  top: 1rem;
  left: 0;
  transition: all 0.6s ease;

  &.open {
    transform: translate($sidebarWidth - 48px, 0); // 48px is the width of the toggle button
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.closed {
    transform: translate(0, 0);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus {
    box-shadow: none;
  }

  svg {
    font-size: 1.5rem;
    margin-bottom: 2px;
  }
}

// Large screen
@media (min-width: $size-xl) {
  .has-sidebar {
    padding-left: $sidebarWidth + 20px;
  }
  .sidebar-toggle {
    display: none;
  }
  .sidebar-navigation {
    transform: none !important;
  }
}

/* Header navigation
============================================ */

.header-navigation {
  position: absolute;
  left: auto;
  top: 1rem;
  right: 2rem;
  z-index: 1030;
  margin: 0;
  padding: 0;
  border-radius: $border-radius-lg;
  box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);

  svg {
    font-size: 1.5rem;
  }

  .dropdown-menu {
    border-width: 0;
    box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);
    margin-top: 0.5rem !important;
  }
}

.has-header {
  margin-top: 3.5rem;
}

@media only screen and (max-width: $size-xl) {
  .has-header {
    margin-top: 5rem;
  }
}

/* Powered by
NOTE: used only on Visit Levi -application
============================================ */

.powered-by {
  position: absolute;
  bottom: 70px;
  left: 0;
  padding: 1rem;
  color: $color-jet;
  font-size: 0.75em;

  a {
    color: $color-white;
  }
}

/* Language selector
============================================ */

.language-navigation {
  .nav-link {
    opacity: 0.5;
    padding-top: 0;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid $color-ghost-white-2;
  }
  .nav-link.active {
    opacity: 1;
    border-bottom: 2px solid $color-rebecca-purple;
  }
}

.language-picker {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
}

.language-picker-flag {
  margin-right: 5px;
  margin-bottom: 3px;
}

/* Guides
============================================ */

.card.guide {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: $color-ghost-white;
  box-shadow: none;
  cursor: pointer;
}

.card.selected {
  font-weight: 600;
  svg {
    color: $color-purple-plum;
  }
}

.card.locked {
  opacity: 0.5;
}

.loading-spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($color-white, 0.25);
  z-index: 100;
  margin: 0 -15px;

  .loading-spinner-icon {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    height: 40px;
    width: 40px;
  }
}

.list-group-item.selected {
  .list.image-container,
  .list.video-container,
  .list.text-container {
    background-color: $color-ghost-white !important;
  }

  .list {
    img,
    &.video-container {
      opacity: 0.5;
    }
  }
}

.image-container {
  &.list {
    height: 95px;
    overflow-y: hidden;
    background-color: $color-black;

    img {
      object-fit: cover;
      height: 95px;
      width: 100%;
    }
  }

  &.preview {
    margin-bottom: 0.25rem;
    background-color: $color-black;

    img {
      width: 100%;
    }
  }

  &.fullsize {
    img {
      height: auto;
      width: 100%;
      max-height: 600px;
      object-fit: contain;
    }
  }
}

.text-container {
  &.list {
    height: 95px;
    overflow-y: hidden;
    background-color: $color-white;
  }

  &.list,
  &.preview {
    padding: 0.5rem 1rem;
  }
}

.video-container {
  background-color: $color-black;
  overflow-y: hidden;
  position: relative;

  &.list {
    height: 95px;

    .video-react-big-play-button {
      top: calc(95px / 2);
      box-shadow: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.preview {
    height: 150px;
    margin-bottom: 0.25rem;

    .video-react-big-play-button {
      top: calc(150px / 2);
    }
  }

  &.fullsize {
    max-height: 600px;
  }
}

.video-player {
  width: auto !important;
}

.video-react {
  .video-react-big-play-button.video-react-big-play-button-center {
    margin-top: -0.75em;
    margin-left: -0.75em;
  }
  .video-react-big-play-button {
    border-radius: 1.5em;
    line-height: calc(1.5em - 3px);
    width: 1.5em;
    border: 3px solid #fff;
    background-color: transparent;
    box-shadow: 2px 3px 6px 0px rgba($color-jet, 0.5);
  }
}

.video-play-button {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 3em;
  color: $color-white;
}

.guide-list-header {
  border-bottom: 1px solid $color-ghost-white;
}

.font-14 {
  font-size: 14px !important;
}

.round-shadow-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: $color-white;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  box-shadow: 2px 3px 6px 0px rgba($color-jet, 0.5);
  z-index: 10;
}

.video-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  color: $color-white;
  font-size: 1.5rem;
  padding: 1rem;
  line-height: 1rem;
  box-shadow: none;
}

.guides-container {
  .button-group {
    position: sticky;
    top: 0;
    z-index: 99;
  }
}

/* Icon Picker
============================================ */

.icon-picker-modal {
  height: 600px;
}

.icon-picker,
.icon-picker-button {
  &:focus,
  &.focus {
    box-shadow: none !important;
  }
}

.icon-picker-button {
  width: 25%;
  height: auto;
  color: $color-jet;
  border-radius: 0;
  border-width: 0;

  &.selected {
    background-color: $color-rebecca-purple;
    color: $color-white;
    border-width: 0;
  }

  .icon-picker-icon {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
}

/* Save Footer button
============================================ */

.fixed-footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  padding: 0.75rem 2rem;
  z-index: 10;
  box-shadow: 0px -1px 5px 0px rgba($color-jet, 0.1);
}

@media (min-width: $size-xl) {
  .fixed-footer-bar {
    margin-left: $sidebarWidth;
  }
}

/* Destination Settings
============================================ */

.background-thumbnail {
  max-height: 150px;
  margin-bottom: 10px;
  cursor: pointer;
}

.background-thumbnail:hover {
  opacity: 0.5;
}
